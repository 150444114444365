import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import { red, grey } from "@material-ui/core/colors";

import history from "./services/history";
import Routes from "./routes";
import Footer from "./components/footer";
import Drawer from "./components/Drawer";

function App() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: red[900],
      },
      primary: {
        main: grey[900],
      },
      inherit: {
        main: "#2d6685",
      },
    },
  });

  return (
    <BrowserRouter history={history}>
      <ThemeProvider theme={theme}>
        <Drawer />
        <div
          style={{
            marginLeft: "18rem",
            marginRight: "2rem",
            // marginBottom: "2rem",
            // marginTop: "2rem",
            display: "flex",
            alignContent: "center",
          }}
        >
          <Routes />
        </div>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
