import { Route, Switch } from "react-router-dom";
import Treinamentos from "../pages/Treinamento";
import Home from "../pages/Home";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/treinamento" component={Treinamentos} isPrivate />
    </Switch>
  );
}
