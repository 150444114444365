import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import { Link } from "react-router-dom";

export const ListItems = (
  <div>
    {/* <ListSubheader inset>Links Úteis</ListSubheader> */}
    <a
      href="https://www.redemarajo.com.br/site"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemText primary="Rede Marajó" />
      </ListItem>
    </a>
    <a
      href="http://suporte.redemarajo.com.br/servicedesk/customer/portals"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemText primary="Central de Ajuda" />
      </ListItem>
    </a>
    <a
      href="http://meurh.redemarajo.com.br"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemText primary="Meu RH" />
      </ListItem>
    </a>
    <a
      href="https://www.frotabank.com.br"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemText primary="Frotabank" />
      </ListItem>
    </a>
    <a
      href="https://redefrota.com.br"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemText primary="Rede Frota" />
      </ListItem>
    </a>
    <a
      href="https://www.pegapontos.com.br"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemText primary="Pega Pontos" />
      </ListItem>
    </a>
    {/* <Link
      to="/treinamento"
      color="inherit"
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <ListItem button>
        <ListItemText primary="Treinamentos" />
      </ListItem>
    </Link> */}

    {/* <a
      href="../pages/Treinamento"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemText primary="Treinamentos" />
      </ListItem>
    </a> */}
  </div>
);
