/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, Icon, Toolbar, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import { makeStyles } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import Fab from "@mui/material/Fab";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CallIcon from "@mui/icons-material/Call";

const useStyles = makeStyles(() => ({}));

const drawerWidth = 240;

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

function Footer(props) {
  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          top: "auto",
          bottom: 0,
        }}
      >
        <Toolbar>
          {/* <Button
            color="primary"
            href="https://storage.googleapis.com/static.rmti7.com/smartclient.zip"
            style={{
              height: "3rem",
              paddingLeft: "3rem",
              paddingRight: "3rem",
              color: "black",
              fontSize: "0.8rem",
            }}
          >
            <FileDownloadIcon
              style={{
                color: "black",
                marginRight: "2px",
              }}
            />
            Baixe o SmartClient
          </Button> */}
          <Box sx={{ flexGrow: 1 }} />
          <CallIcon
            style={{
              color: "black",
              marginRight: "5px",
            }}
          />
          <Typography
            sx={{
              color: "black",
            }}
          >
            corporativo: (62) 3999-6262
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

Footer.propTypes = {
  window: PropTypes.func,
};

export default Footer;
