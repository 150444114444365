import {
  Box,
  Divider,
  FormControl,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

export default function Treinamento() {
  const url_videos = [
    {
      title: "Login FastPDV",
      video: "login_fast.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/login_fast.mp4",
    },
    {
      title: "Abertura de turno",
      video: "abertura_de_turno_FAST_PDV.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/abertura_de_turno_FAST_PDV.mp4",
    },
    {
      title: "Venda com consumidor padrão",
      video: "realizando_venda_FAST_PDV.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/realizando_venda_FAST_PDV.mp4",
    },
    {
      title: "Venda com emissor de DANFE",
      video: "venda_DANFE.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/venda_DANFE.mp4",
    },
    {
      title: "Venda de produto",
      video: "venda_produto.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/venda_produto.mp4",
    },
    {
      title: "Abastecimentos baixados",
      video: "abastecimentos_baixados_2.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/abastecimentos_baixados_2.mp4",
    },

    {
      title: "Cancelamento de venda pós-turno",
      video: "cancelamento_pos_turno.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/cancelamento_pos_turno.mp4",
    },
    {
      title: "Compensação no PDV",
      video: "compensacao.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/compensacao.mp4",
    },
    {
      title: "Depósito no PDV",
      video: "deposito_pdv.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/deposito_pdv.mp4",
    },
    {
      title: "Dividir e aglutinar abastecimentos",
      video: "dividir_aglutinar.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/dividir_aglutinar.mp4",
    },
    {
      title: "Inclusão manual de abastecimento",
      video: "incluir_abastecimento.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/incluir_abastecimento.mp4",
    },
    {
      title: "Orçamentos",
      video: "orcamentos.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/orcamentos.mp4",
    },
    {
      title: "Aferição de abastecimentos",
      video: "realizando_afericao.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/realizando_afericao.mp4",
    },

    {
      title: "Sagria e suprimento de caixa",
      video: "sagria_suprimento.mp4",
      url: "https://pub-97bfe8059d4a46cb96d9186cd20f5b4b.r2.dev/sagria_suprimento.mp4",
    },
  ];

  return (
    <FormControl fullWidth sx={{ marginBottom: "6rem" }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography align="center" variant="h5">
            {" "}
            Treinamento FastPDV{" "}
          </Typography>
        </Grid>
        {url_videos.map((item) => (
          <Grid item xs={12} md={6} lg={4} key={item.url}>
            <FormControl>
              <Paper
                elevation={2}
                sx={{
                  borderRadius: "15px",
                  padding: "0.5rem",
                  width: 360,
                  height: 260,
                }}
              >
                <Box m={2}>
                  <Divider textAlign="left" style={{ fontWeight: "bold" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {" "}
                      {item.title}{" "}
                    </Typography>
                  </Divider>

                  <video
                    controls
                    height={160}
                    width={310}
                    style={{
                      borderRadius: "10px",
                      marginTop: 10,
                      backgroundColor: "#000000",
                    }}
                  >
                    <source src={item.url} />
                  </video>
                </Box>
              </Paper>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
}
