import * as React from "react";

import TextHome from "../components/Text";

export default function Home() {
  return (
    <div>
      <TextHome />
    </div>
  );
}
