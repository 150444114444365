import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";

import Frutal from "../../assets/images/Frutal.jpg";

import { makeStyles } from "@material-ui/styles";

import WebIcon from "@mui/icons-material/Web";

const useStyles = makeStyles(() => ({
  logo: {
    height: "3.0rem",
    padding: "0.1rem",
    marginTop: "0.6rem",
  },
  p: {
    textAlign: "justify",
  },
}));

export default function TextHome() {
  const classes = useStyles();
  return (
    <section>
      <main>
        <section
          className="container flex flex--centro"
          style={{
            fontSize: "15px",
          }}
        >
          <article>
            <h2> Quem Somos </h2>
            <p className={classes.p}>
              Somos o maior Grupo Revendedor de Combustíveis da Shell do Brasil,
              líderes do mercado Rodoviário e a única Rede que atende toda a
              Rodovia Belém-Brasília, porque há mais de 20 anos desenvolvemos
              soluções para os Operadores Logísticos Rodoviários. Contamos ainda
              com o melhor e mais completo Rodo Shopping do Brasil, o Marajó
              Grande Belém, que é fruto de uma importante parceria com o Grupo
              Ipiranga. Somos também pioneiros em serviços aos Transportadores e
              Motoristas, como o sistema de abastecimento Anti Fraude, Lavagem
              profissional de Frotas, e continuaremos desenvolvendo soluções
              para que nossos clientes alcancem melhores resultados em suas
              operações.
            </p>
            <img src={Frutal} alt=" Marajó Frutal" width="100%" />
          </article>
        </section>
        <section
          style={{
            fontSize: "15px",
          }}
        >
          {/* <article>
            <h2> Acesse o Protheus Online! </h2>
            <p>Você pode acessar de onde estiver, rápido, pratico e seguro.</p>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <div className="container flex flex--centro">
                  <Button
                    color="primary"
                    href="http://protheus.redemarajo.com.br"
                    style={{
                      height: "3rem",
                      paddingLeft: "4rem",
                      paddingRight: "3.6rem",
                      marginBottom: "4rem",
                      color: "black",
                      fontSize: "0.8rem",
                    }}
                  >
                    <WebIcon
                      style={{
                        color: "black",
                        marginRight: "3px",
                      }}
                    />
                    Protheus Online
                  </Button>
                </div>
              </Grid>
            </Grid>
          </article> */}
        </section>
      </main>
    </section>
  );
}
